import { useEffect } from 'react';
import NProgress from 'nprogress';
// import { Box } from '@mui/material';
import Box from '@mui/material/Box';
// import LockIcon from '../icons/Lock';
// import { display } from '@material-ui/system';
// import CircularProgress from '@mui/material/CircularProgress';
import { CircularProgress } from '@mui/material';
import {TailSpin} from 'react-loader-spinner';

const LoadingScreen = () => {
  useEffect(() => {
    NProgress.start();

    return () => {
      NProgress.done();
    };
  }, []);

  return (
    // <Box
    //   display="flex"
    //   justifyContent="center"
    //   alignItems="center"
    //   sx={{
    //     backgroundColor: 'background.paper',
    //     minHeight: '100%',
    //   }}
    // >
    //     <CircularProgress />
    // </Box>
    <><div style={{    display: 'flex',
    width: '100vw',
    zIndex: 9999,
    backgroundColor: 'rgba(0,0,0,0.5)',
    justifyContent: 'center',
    alignItems: 'center',
    height: "100vh",
    top: 0,
    left: 0,
    position: 'fixed'}}><TailSpin
    visible={true}
    height="80"
    width="80"
    color="#4fa94d"
    ariaLabel="tail-spin-loading"
    radius="1"
    wrapperStyle={{}}
    wrapperClass=""
    /></div></>
  );
};

export default LoadingScreen;
