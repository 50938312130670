import axios from "axios";
import { baseURL } from "../Environment";

const ApiRequest = axios.create({
    baseURL,
    headers: {
        'Authorization' : `${localStorage.getItem('token')}`
    },
    // httpsAgent: new https.Agent({
    //     rejectUnauthorized: false
    // })
});

const responseHandler = response => {
    return response;
}

const errorHandler = error => {
    //console.log(error.response);
    return Promise.reject(error);
} 

ApiRequest.interceptors.response.use(
    (response) => responseHandler(response),
    (error) => errorHandler(error)
)

export default ApiRequest;