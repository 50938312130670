import routes from './router'
import { useRoutes } from "react-router-dom";
import { useEffect, useCallback } from 'react';
import useSettings from './hooks/useSettings';
import { createCustomTheme } from './theme';

import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import SettingsDrawer from './components/SettingDrawer';
import { useSelector } from 'react-redux';
import {TailSpin} from "react-loader-spinner";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import DateAdapter from '@mui/lab/AdapterDateFns';

// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import {getMetaData} from './Api/LoginApi';
import { useDispatch } from 'react-redux';
import { loadingActions } from './slice/loading';
import { authActions } from './slice/auth';
import useMounted from './hooks/useMounted';

function App() {
  const content = useRoutes(routes);
  const { settings } = useSettings();
	const mounted = useMounted();
  const isLoading = useSelector(state=>state.loading.counter);
  const dispatch = useDispatch();
  const theme = createCustomTheme({
    roundedCorners: settings.roundedCorners,
    theme: settings.theme
  });
  const getMeta = useCallback(async () => {
		try {
			dispatch(loadingActions.increment());
			const data = await getMetaData();

			if (mounted.current) {
				dispatch(authActions.metaData(data));
			}
		} catch (err) {
		console.error(err);
		} finally{
			dispatch(loadingActions.decrement());
		}
	}, [mounted]);
  useEffect(() => {
    getMeta();
  },[getMeta]);

  return (<>
    <ThemeProvider theme={theme}>
      {/* <LocalizationProvider dateAdapter={DateAdapter}> */}
      <ToastContainer 
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
      />  
      <SettingsDrawer />
      <CssBaseline/>
          {content}
      {/* </LocalizationProvider> */}
    </ThemeProvider>
    {isLoading>0 && <><div style={{    display: 'flex',
      width: '100vw',
      zIndex: 9999,
      backgroundColor: 'rgba(0,0,0,0.5)',
      justifyContent: 'center',
      alignItems: 'center',
      height: "100vh",
      top: 0,
      left: 0,
      position: 'absolute'}}><TailSpin
      visible={true}
      height="80"
      width="80"
      color="#4fa94d"
      ariaLabel="tail-spin-loading"
      radius="1"
      wrapperStyle={{}}
      wrapperClass=""
      /></div></>}
</>
  );
}

export default App;
