import { Navigate } from "react-router";
import { PropTypes } from "prop-types";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router";
// import { grid } from "@material-ui/system";

const AuthGuard=(props)=>{
    const isAuth = useSelector(state=>state.auth.isAuth);
    // const isAuth2 = useSelector(state=>state.auth);
    const userPermisions = useSelector(state=>state.auth.arr);
    // //console.log("state.auth.isAuth)",isAuth)
    // //console.log("state.auth)",isAuth2)
    // //console.log("state",userPermisions)
    let arr = []
    if(userPermisions=="string"){
         arr = userPermisions.split(",");
    }
    else{
         arr = userPermisions;
    }
    
    // if(userPermisions.dashboard==true){
    //     //console.log("dashboard1",userPermisions.dashboard)
    // }
    // Admin roles
    // 0 = PageLayout
    // 1 = Dashboard
    // 2= customer-management
    // 3= supplier-management
    // 4= ticket-management
    // 5= transaction-history
    // 6= payment-report
    // 7= refund-request
    // 8= new-horizon-management (Commission)
    // 9= employee-management (Admins)
    // 10= job (Orders)
    // 11= category-management
    // 

    const {children}=props
   
    if(!isAuth){
        return <Navigate to="/login"></Navigate>
    }
    if(arr.includes(Number(children.key))){
        // //console.log("children",children);
        // //console.log("isAuth",isAuth);
        // window.history.pushState(window.location.href,'history');
        return children
    }
    else {
        // navigate('');
        // return children
        // //console.log("children failed",children.key);
        return <div style={{display:'grid',height:'90vh'}}>
            <div style={{margin:'auto'}}><h1>Access Denied</h1></div>
            </div>
    }
}
AuthGuard.prototype={
    children:PropTypes.node
}
export default AuthGuard
  