import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    TextField,
    Checkbox,
    Typography,
    FormControlLabel,
    
  } from '@mui/material';
  import FormLabel from '@mui/material/FormLabel';
  import Logo from '../pwa_logo.png';
  import { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router';
  import { Navigate } from 'react-router';
  // import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
  import { useDispatch, useSelector } from 'react-redux';
  import { login } from '../slice/auth';
  import { toast } from 'react-toastify';
  import { loadingActions } from '../slice/loading';
  import { useLocation } from "react-router-dom";
import { updateAdmin, updateCategories } from '../Api/AdminApi';

  const AdminEdit = () => {

    const [password, setPassword] = useState(null);
    const [isInValid, setIsInValid] = useState(false);

    const navigate = useNavigate();
    const dispatch= useDispatch();
    const {state} =useLocation()
    const [employeeId, setEmployeeId] = useState();
    // const [userId, setUserId] = useState();
    const [firstName, setfirstName] = useState();
    const [lastName, setLastName] = useState();
    const [email, setEmail] = useState();
    const [dashboard, setDashboard] = useState(false);
    const [customer, setCustomer] = useState(false);
    const [supplier, setSupplier] = useState(false);
    const [ticket, setTicket] = useState(false);
    const [transactions, setTransactions] = useState(false);
    const [payment, setPaymment] = useState(false);
    const [refund, setRefund] = useState(false);
    const [horizon, setHorizon] = useState(false);
    const [employee, setEmployee] = useState(false);
    const [job, setJob] = useState(false);
    const [category, setCategory] = useState(false);


    useEffect(() => {
      //console.log(state);
      //console.log("state id",state.id);
      // if(state.id !=null){
      //   setUserId(state.id)
      // }

      if(state.firstName !=null){
        setfirstName(state.firstName)
      }

      if(state.lastName !=null){
        setLastName(state.lastName)
      }
      
      if(state.email !=null){
        setEmail(state.email)
      }

      if(state.dashboard !=null){
        setDashboard(state.dashboard)
      }

      if(state.customer !=null){
        setCustomer(state.customer)
      }

      if(state.supplier !=null){
        setSupplier(state.supplier)
      }

      if(state.ticket !=null){
        setTicket(state.ticket)
      }

      if(state.transactions !=null){
        setTransactions(state.transactions)
      }

      if(state.payment !=null){
        setPaymment(state.payment)
      }

      if(state.refund !=null){
        setRefund(state.refund)
      }

      if(state.horizon !=null){
        setHorizon(state.horizon)
      }

      if(state.employee !=null){
        setEmployee(state.employee)
      }

      if(state.job !=null){
        setJob(state.job)
      }

      if(state.category !=null){
        setCategory(state.category)
      }

    }, []);
    async function updateCurrentAdmin(){
        try{ 
          // dispatch(loadingActions.increment());
          const userId =state.id
          //console.log("userId",userId);
          const data= await updateAdmin(userId,email,firstName,lastName,
            dashboard,customer,supplier,ticket,transactions,payment,refund,
            horizon,employee,job,category)
          
            if(data){
              toast.success("Admin Updated")
              navigate(`/employee-management`)
            }
            else{
              toast.error(data.message);
            }
        }
        catch(error){
            toast.error(error); 
            //console.log(error);
        }finally{
          // dispatch(loadingActions.decrement());
        }

    }
    // if(isAuth){
    //   return<Navigate to="/"/>
    // }

    const onEmailChange=(event)=>{
        setIsInValid(false);
        setEmail(event.target.value);
    }
    const onPasswordChange=(event)=>{
        setIsInValid(false);
        setPassword(event.target.value);
    }

    const onSubmit=(event)=>{
        event.preventDefault();
        if(email==null||email==''){
          toast.error("Email can not be empty");
     
        }
        else{
          // //console.log(sali);
          updateCurrentAdmin()
        // getUser(email,password);
        }

    }
    return(
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        // p: '120',
        paddingTop:'180px'
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{margin:'auto'}}>

        <CardContent
          sx={{
              backgroundColor:"primary.main",
            display: 'flex',
            flexDirection: 'column',
            p:'0'
          }}
        >
          
            <Box sx={{display:'flex', flexDirection:'inherit', alignItems:'center'}}
            >
              <Typography
                color="white"
                variant="h3"
              >
                Edit Admin
              </Typography>
            </Box>
            </CardContent>

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p:'1'
                }}
        >
            <Box
              sx={{
                flexGrow: 1,
                mt: 2
              }}>
              <form onSubmit={(e)=>onSubmit(e)}>
                <FormLabel component="legend" sx={{marginTop:'5px'}}>Email</FormLabel>
                <TextField
                  fullWidth
                  required
                  // label="Email"
                  id="outlined"
                  margin="normal"
                  name="email"
                  type="email"
                  
                  // variant="outlined"
                  value={email}
                  onChange={(e)=>onEmailChange(e)}
                  error={isInValid}
                  helperText={isInValid? 'Invalid Email' : ' '}
                  sx={{marginBottom:'2px',marginTop:'2px'}}
                />
                <FormLabel component="legend" sx={{marginTop:'5px'}}>Name</FormLabel>
                <TextField
                  fullWidth
                  // label="Name"
                  margin="normal"
                  name="firstName"
                  type="default"
                  variant="outlined"
                  value={firstName}
                  defaultValue={firstName}
                  onChange={(e)=>setfirstName(e.target.value)}
                  // error={isInValid}
                  // helperText={isInValid? 'Invalid Email' : ' '}
                  sx={{marginBottom:'2px',marginTop:'2px'}}
                />
                 <FormLabel component="legend" sx={{marginTop:'5px'}}>Surname</FormLabel>
                <TextField
                  fullWidth
                  // label="Surname"
                  margin="normal"
                  name="lsatname"
                  type="default"
                  variant="outlined"
                  value={lastName}
                  defaultValue={lastName}
                  onChange={(e)=>setLastName(e.target.value)}
                  // error={isInValid}
                  // helperText={isInValid? 'Invalid Email' : ' '}
                  sx={{marginBottom:'2px',marginTop:'2px'}}
                />
                 <FormLabel component="legend" sx={{marginTop:'5px'}}>Accreditations</FormLabel>
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={dashboard}
                        onChange={(e)=>setDashboard(!dashboard)}
                        
                    />
                    )}
                    label="Dashboard"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={customer}
                        onChange={(e)=>setCustomer(!customer)}
                        
                    />
                    )}
                    label="Customer"
                />
              <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={supplier}
                        onChange={(e)=>setSupplier(!supplier)}
                        
                    />
                    )}
                    label="Supplier"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={ticket}
                        onChange={(e)=>setTicket(!ticket)}
                        
                    />
                    )}
                    label="Ticket"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={transactions}
                        onChange={(e)=>setTransactions(!transactions)}
                        
                    />
                    )}
                    label="Transactions"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={payment}
                        onChange={(e)=>setPaymment(!payment)}
                        
                    />
                    )}
                    label="Payment"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={refund}
                        onChange={(e)=>setRefund(!refund)}
                        
                    />
                    )}
                    label="Refund"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={horizon}
                        onChange={(e)=>setHorizon(!horizon)}
                        
                    />
                    )}
                    label="Horizon"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={employee}
                        onChange={(e)=>setEmployee(!employee)}
                        
                    />
                    )}
                    label="Employee"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={job}
                        onChange={(e)=>setJob(!job)}
                        
                    />
                    )}
                    label="Job"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={category}
                        onChange={(e)=>setCategory(!category)}
                        
                    />
                    )}
                    label="Category"
                />
                <Button
                    color="error"
                    fullWidth
                    size="large"
                    // type="submit"
                    variant="contained"
                    onClick={()=> navigate(`/employee-management`)}
                    
                >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
    );
};
  
  export default AdminEdit;
  