import { Suspense, lazy } from 'react';
// import { Navigate } from 'react-router-dom';
import LoadingScreen from './components/LoadingScreen';
import PageLayout from './components/layout/PageLayout';
import AuthGuard from './components/AuthGuard';
import CategoryEdit from './pages/CategoryEdit';
import CategoryAdd from './pages/CategoryAdd';
import AdminEdit from './pages/AdminEdit';
import AdminAdd from './pages/AdminAdd';

const Loadable = (Component) => (props) => (
    <Suspense fallback={<LoadingScreen />}>
      <Component {...props} />
    </Suspense>
  );

const LoginPage = Loadable(lazy(()=> import('./pages/LoginPage')));
const Dashboard = Loadable(lazy(() => import('./pages/Dashboard')));

const CustomerManagement = Loadable(lazy(() => import('./pages/CustomerManagement')));
const CustomerDetails = Loadable(lazy(() => import('./pages/CustomerDetails')));
const CustomerOrderDetails = Loadable(lazy(()=> import ('./pages/CustomerOrderDetails'))); 

const PaymentManagement = Loadable(lazy(() => import('./pages/PaymentManagement')));
const SupplierPaymentReport = Loadable(lazy(() => import('./pages/SupplierPaymentReport')));
const CustomerPaymentReport = Loadable(lazy(()=> import('./pages/CustomerPaymentReport')));
const NewHorizonPaymentReport = Loadable(lazy(()=> import('./pages/NewHorizonPaymentReport')));

const SupplierManagement = Loadable(lazy(() => import('./pages/SupplierManagement')));
const SupplierDetails = Loadable(lazy(()=> import('./pages/SupplierDetails')));
const SupplierOrderDetails = Loadable(lazy(()=> import('./pages/SupplierOrderDetails')));

const TicketManagement = Loadable(lazy(()=> import('./pages/TicketManagement')));
const TicketItem = Loadable(lazy(()=> import('./components/ticket/TicketItem')));

const RefundRequest = Loadable(lazy(()=> import('./pages/RefundRequest')));
const HorizonManagement = Loadable(lazy(()=> import('./pages/HorizonManagement')));
const EmployeeManagement = Loadable(lazy(()=> import('./pages/EmployeeManagement')));
const Job = Loadable(lazy(()=> import('./pages/Job')));


const ResetPassword = Loadable(lazy(()=> import('./components/ResetPassword')));
const PasswordChangeSuccess = Loadable(lazy(()=> import('./pages/SuccessPage')));

const CategoryManagement = Loadable(lazy(() => import('./pages/CategoryManagement')));

const routes=[
    {
        path:'',
        element: <AuthGuard><PageLayout key={0}/></AuthGuard>,
        // element:<PageLayout />,
        children: [
            {
                path:'/',
                // element: <AuthGuard><Dashboard key={1}/></AuthGuard>
                element: <Dashboard key={1}/>
            },
            {
                path:'customer-management',
                children:[
                    {
                    path : '',
                    element:<AuthGuard><CustomerManagement key={2}/></AuthGuard>
                    },
                    {
                    path:'details/:userId',
                    element:<AuthGuard><CustomerDetails key={2}/></AuthGuard>
                    },
                    {
                    path:'details/:userId/:orderId',
                    element:<AuthGuard> <CustomerOrderDetails key={2}/></AuthGuard>
                    }
                ]
            },
            {
                path:'supplier-management',
                children:[
                    {
                        path:'',
                        element:<AuthGuard><SupplierManagement key={3}/></AuthGuard>
                    },
                    {
                        path:'details/:supplierId',
                        element:<AuthGuard><SupplierDetails key={3}/></AuthGuard>
                    },
                    {
                        path:'details/:supplierId/:orderId',
                        element:<AuthGuard><SupplierOrderDetails key={3}/></AuthGuard>
                    }
                ]
            },
            {
                path:'transaction-history',
                element: <AuthGuard><PaymentManagement key={5}/></AuthGuard>
            },
            {
                path:'payment-report',
                children:[
                    {
                        path:'supplier-report',
                        element:<AuthGuard> <SupplierPaymentReport key={6}/></AuthGuard>
                    },
                    {
                        path:'new-horizon',
                        element:<AuthGuard> <NewHorizonPaymentReport key={6}/></AuthGuard>
                    },
                    {
                        path:'customer-report',
                        element:<AuthGuard> <CustomerPaymentReport key={6}/></AuthGuard>
                    }
              
                ]
            },
            {
                path:'ticket-management',
                children:[
                    {
                        path:'',
                        element:<AuthGuard><TicketManagement key={4}/></AuthGuard>
                    },
                    {
                        path:':type/:ticketId',
                        element:<AuthGuard><TicketItem key={4}/></AuthGuard>
                    },
                    {
                        path:'detalis/:ticketId',
                        element:<AuthGuard><TicketItem key={4}/></AuthGuard>
                    }
                ]
            },
            {
                path:'refund-request',
                children:[
                    {
                        path:'',
                        element:<AuthGuard><RefundRequest key={7}/></AuthGuard>
                    },
                    {
                        path:'details/:orderId',
                        element:<AuthGuard><SupplierOrderDetails key={7}/></AuthGuard>
                    }
                ]
            },
            {
                path:'new-horizon-management',
                element:<AuthGuard><HorizonManagement key={8}/></AuthGuard>
            },
            {
                path:'employee-management',
                children:[
                    {
                        path:'',
                        element:<AuthGuard><EmployeeManagement key={9}/></AuthGuard>
                    },
                    {
                        path:'edit/:id',
                        element:<AuthGuard><AdminEdit key={9}/></AuthGuard>
                    },
                    {
                        path:'add',
                        element:<AuthGuard><AdminAdd key={9}/></AuthGuard>
                    },
                    
                ]
                
            },
            {
                path:'job',
                element:<AuthGuard><Job key={10}/></AuthGuard>
            },
            {
                path:'category-management',
                children:[
                    {
                    path : '',
                    element:<AuthGuard><CategoryManagement key={11}/></AuthGuard>
                    },
                    {
                    path:'edit/:id',
                    element:<AuthGuard><CategoryEdit key={11}/></AuthGuard>
                    },
                    {
                        path:'add',
                        element:<AuthGuard><CategoryAdd key={11}/></AuthGuard>
                    },
                ]
            }
        ]
    },
    {
        path:"login",
        element:<LoginPage />
    },
    {
        path:'reset-password',
        element: <ResetPassword />
    },
    {
        path:'password-change-success',
        element:<PasswordChangeSuccess />
    
    }
]

export default routes