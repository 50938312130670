import { useEffect } from 'react';
import { Link as RouterLink, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { 
  Avatar, 
  Box, 
  //Button, 
  Divider, 
  Drawer, 
  //Link, 
  Typography } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
// import ChartPieIcon from '../../icons/ChartPie';
import ChartSquareBarIcon from '../../icons/ChartSquareBar';
import UserIcon from '../../icons/User';
import NavSection from './NavSection';
import Scrollbar from './Scrollbar';
import CashIcon from '../../icons/Cash'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber';
import PlumbingIcon from '@mui/icons-material/Plumbing';
import ReceiptIcon from '@mui/icons-material/Receipt';
import WorkIcon from '@mui/icons-material/Work';
import WorkOutline from '@mui/icons-material/WorkOutline';
import CategoryIcon from '@mui/icons-material/Category';

import { useSelector } from 'react-redux';
import Logo from '../../pwa_logo.png';

const sections = [
  {
    title:'1',
    items: [
      {
        key:1,
        title: 'Dashboard',
        path: '/',
        icon: <ChartSquareBarIcon fontSize="small" />
      },
      {
        key:2,
        title: 'Customer Management',
        path: '/customer-management',
        icon: <UserIcon fontSize="small" />
      },
      {
        key:3,
        title: 'Supplier Management',
        path: '/supplier-management',
        icon: <PlumbingIcon fontSize="small" />
      },
      {
        key:4,
        title: 'Ticket Management',
        path: '/ticket-management',
        icon: <ConfirmationNumberIcon fontSize="small" />
      },
      {
        key:5,
        title: 'Transaction History',
        path: '/transaction-history',
        icon: <CashIcon fontSize="small" />
      },
      {
        key:6,
        title: 'Payment Report',
        path: '/payment-report',
        icon: <ReceiptIcon fontSize="small" />,
        children: [
          {
            title: 'Payouts',
            path: '/payment-report/supplier-report'
          },
          {
            title: 'New Horizon',
            path: '/payment-report/new-horizon'
          },
          {
            title: 'Customer Refunds',
            path: '/payment-report/customer-report'
          }
     
        ]
      },
      {
        key:7,
        title: 'Refund Request',
        path: '/refund-request',
        icon: <ReceiptIcon fontSize="small" />
      },
      {
        key:8,
        title: 'New Horizon Management',
        path: '/new-horizon-management',
        icon: <ReceiptIcon fontSize="small" />
      },
      {
        key:9,
        title: 'Employee Management',
        path: '/employee-management',
        icon: <WorkIcon fontSize="small"></WorkIcon>
      },
      {
        key:10,
        title: 'Job',
        path: '/job',
        icon: <WorkOutline fontSize="small"></WorkOutline>
      },
      {
        key:11,
        title: 'Category Management',
        path: '/category-management',
        icon: <CategoryIcon fontSize="small"></CategoryIcon>
      },
    ]
  },
];
const filterSection=(arr)=>{
  let sectionfilter = sections[0].items.filter((item)=>arr.includes(item.key))
  sections[0].items=sectionfilter
}
const SideBar = (props) => {
  // let section ={title:'1',items:[]}
  const { onMobileClose, openMobile } = props;
  const location = useLocation();
  const lgUp = useMediaQuery((theme) => theme.breakpoints.up('lg'));

  const userName = localStorage.getItem('userName');
  const userEmail = localStorage.getItem('userEmail');
  const userProfilePic = localStorage.getItem('userProfilePic');
  useEffect(()=>{
    filterSection([1,2,3,4,5,6,7,8,9,10,11]);
  },[])

  useEffect(() => {
    if (openMobile && onMobileClose) {
      onMobileClose();
    }
  }, [location.pathname]);

  const content = (
    <Box
      sx={{
        display: 'flex',
        flexDirection: 'column',
        height: '100%'
      }}
    >
      <Scrollbar options={{ suppressScrollX: true }}>
        <Box
          sx={{
            display: {
              lg: 'none',
              xs: 'flex'
            },
            justifyContent: 'center',
            p: 2
          }}
          backgroundColor="primary.main"
        > 
          <Box sx={{height: '90px'}}>
            <RouterLink to="/">
            <Avatar
              src={Logo}
              sx={{
                display: 'inline',
                height: 40,
                width: 40
              }}
            /> 
            </RouterLink>
          </Box>
        </Box>
        <Box sx={{ p: 2 }}>
          <Box
            sx={{
              alignItems: 'center',
              backgroundColor: 'background.default',
              borderRadius: 1,
              display: 'flex',
              flexDirection:'column',
              overflow: 'hidden',
              p: 2
            }}
          >
            <RouterLink to="/">
              <Avatar
                src={userProfilePic}
                sx={{
                  cursor: 'pointer',
                  height: 48,
                  width: 48
                }}
              />
            </RouterLink>
            <Box>
              <Typography
                color="textPrimary"
                variant='body1'
                align='center'
              >
                {userName}
              </Typography>
              <Typography
                color="textSecondary"
                variant="body2"
              >
                {userEmail}
              </Typography>
            </Box>
          </Box>
        </Box>
        <Divider />
        <Box sx={{ p: 2 }}>
          {sections.map((section) => (
            <NavSection
              key={section.title}
              pathname={location.pathname}
              sx={{
                '& + &': {
                  mt: 3
                }
              }}
              {...section}
            />
          ))}
        </Box>
      </Scrollbar>
    </Box>
  );

  if (lgUp) {
    return (
      <Drawer
        anchor="left"
        open
        PaperProps={{
          sx: {
            backgroundColor: 'background.paper',
            height: 'calc(100% - 64px) !important',
            top: '64px !Important',
            width: 280
          }
        }}
        variant="permanent"
      >
        {content}
      </Drawer>
    );
  }

  return (
    <Drawer
      anchor="left"
      onClose={onMobileClose}
      open={openMobile}
      PaperProps={{
        sx: {
          backgroundColor: 'background.paper',
          width: 280
        }
      }}
      variant="temporary"
    >
      {content}
    </Drawer>
  );
};

SideBar.propTypes = {
  onMobileClose: PropTypes.func,
  openMobile: PropTypes.bool
};

export default SideBar;
