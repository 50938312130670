import { useState } from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import { AppBar, Box, IconButton, Toolbar, Link, Avatar,Dialog,Button,Container,Typography } from '@mui/material';
import { experimentalStyled } from '@mui/material/styles';
import MenuIcon from '../../icons/Menu';
import Logo from '../../pwa_logo.png';
import { authActions } from '../../slice/auth';
import { useDispatch } from 'react-redux';
import SettingsDrawer from '../SettingDrawer';
import WarningIcon from '@mui/icons-material/WarningOutlined';
import { alpha } from '@mui/material/styles';

const DashboardNavbarRoot = experimentalStyled(AppBar)(({ theme }) => ({
  ...(theme.palette.mode === 'light' && {
    backgroundColor: theme.palette.primary.main,
    // backgroundColor: "#1203ae" ,
    boxShadow: 'none',
    color: theme.palette.primary.contrastText
  }),
  ...(theme.palette.mode === 'dark' && {
    backgroundColor: theme.palette.background.paper,
    borderBottom: `1px solid ${theme.palette.divider}`,
    boxShadow: 'none'
  }),
  zIndex: theme.zIndex.drawer + 100
}));
const DashboardNavbar = (props) => {
  const dispatch = useDispatch();
  const { onSidebarMobileOpen, ...other } = props;
  const [logout,setLogout] = useState();
  
  const onLogOut=()=>{
    localStorage.clear();
    dispatch(authActions.logout(false));
  }

  return (
    <DashboardNavbarRoot {...other}>
      <Toolbar sx={{ minHeight: 64 }}>
        <IconButton
          color="inherit"
          onClick={onSidebarMobileOpen}
          sx={{
            display: {
              lg: 'none'
            }
          }}
        >
          <MenuIcon fontSize="small" />
        </IconButton>
        <Box sx={{height:'50px'}}>
        <RouterLink to="/">
          {/* <Logo
            sx={{
              display: {
                lg: 'inline',
                xs: 'none'
              },
              height: 40,
              width: 40
            }}
          /> */}
          <Avatar
            src={Logo}
            // sx={{
            //   display: {
            //     lg: 'inline',
            //     xs: 'none'
            //   },
            //   height: 40,
            //   width: 40
            // }}
            sx={{
              display:{
                lg: 'inline',
                xs:'none'
              },
              height: '50px'
            }}
          />
        </RouterLink>
        </Box>
        <Box
          sx={{
            flexGrow: 1,
            ml: 2
          }}
        />
        <Box sx={{
          mr:6
        }}>
          <Button onClick={()=>setLogout(true)} sx={{color:'white'}}>
            Logout
          </Button>
          <Dialog
              open={logout}
              onClose={() => setLogout(false)}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              sx={{
                zIndex:1320,
              }}
            >
              <Container maxWidth="sm" sx={{display: 'flex',flexDirection: 'column',justifyContent: 'space-evenly',width:'350px', height:'200px'}}>
                <Box
                  sx={{
                    display: 'flex',
                    flexDirection:'column',
                  }}
                >
                      <Typography
                        color="textPrimary"
                        variant="h4"
                      >
                      Log out
                      </Typography>
                </Box>
                <Box>
                      <Typography
                        color="textSecondary"
                        variant="body1"
                      >
                        Are you sure you want to log out ?
                      </Typography>
                    </Box>
                <Box
                  sx={{
                    display: 'flex',
                    justifyContent: 'flex-end',
                  }}
                >
                  <Button
                    // sx={{ mr: 2 }}
                    variant="outlined"
                    onClick={() => setLogout(false)} color="error">
                    Cancel
                  </Button>
                  <Button
                    sx={{
                      backgroundColor: 'error.main',
                      '&:hover': {
                        backgroundColor: 'error.dark'
                      },
                      ml:2
                    }}
                    variant="contained"
                    onClick={() => onLogOut()} color="success" autoFocus >
                    Log out
                  </Button>
                </Box>
              </Container>
            </Dialog>
        </Box>
      </Toolbar>
    </DashboardNavbarRoot>
  );
};

DashboardNavbar.propTypes = {
  onSidebarMobileOpen: PropTypes.func
};

export default DashboardNavbar;
