import {
    Box,
    Button,
    Card,
    CardContent,
    Container,
    Divider,
    Link,
    TextField,
    Checkbox,
    Typography,
    FormControlLabel,
    Avatar,
  } from '@mui/material';
  import FormLabel from '@mui/material/FormLabel';
  import Logo from '../pwa_logo.png';
  import { useState, useEffect } from 'react';
  import { useNavigate } from 'react-router';
  import { Navigate } from 'react-router';
  // import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
  import { useDispatch, useSelector } from 'react-redux';
  import { login } from '../slice/auth';
  import { toast } from 'react-toastify';
  import { loadingActions } from '../slice/loading';
  import { useLocation } from "react-router-dom";
  import { updateCategories } from '../Api/AdminApi';
  import AWS from 'aws-sdk';
  import CloudUploadIcon from '@mui/icons-material/CloudUpload';
  const CategoryEdit = () => {
    const [email,setEmail] = useState(null);
    const [password, setPassword] = useState(null);
    const [isInValid, setIsInValid] = useState(false);
    const [categoryImage, setCategoryImage] =useState(null)
    const [categoryImageView, setCategoryImageView] =useState(null)
    const [categoryImageName, setCategoryImageName] =useState('')
    const navigate = useNavigate();
    const dispatch= useDispatch();
    const {state} =useLocation()
    const [description, setDescription] = useState(state.description);
    const [msba, setmsba] = useState(false);
    const [cidb, setcidb] = useState(false);
    const [eaab, seteaab] = useState(false);
    const [saqa, setsaqa] = useState(false);
    const [sacap, setsacap] = useState(false);
    const [hnbrc, sethnbrc] = useState(false);
    const [iopsa, setiopsa] = useState(false);
    const [pirb, setpirb] = useState(false);
    const [medical, setmedical] = useState(false);
    const [sali, setsali] = useState(false);


    useEffect(() => {
      //console.log(state);
      
      // setDescription(state.description)
      if(state.msba !=null){
        //console.log("state",state.msba);
        setmsba(state.msba)
      }
      if(state.cidb !=null){
        setcidb(state.cidb)
      }
      if(state.eaab !=null){
        seteaab(state.eaab)
      }
      if(state.saqa !=null){
        setsaqa(state.saqa)
      }
      if(state.sacap !=null){
        setsacap(state.sacap)
      }
      if(state.hnbrc !=null){
        sethnbrc(state.hnbrc)
      }
      if(state.iopsa !=null){
        setiopsa(state.iopsa)
      }
      if(state.pirb !=null){
        setpirb(state.pirb)
      }
      if(state.medical !=null){
        setmedical(state.medical)
      }
      if(state.sali !=null){
        setsali(state.sali)
      }
      if(state.categoryImage !=null){
          setCategoryImageView(state.categoryImage)
      }
     
      
    
      
     
      

 

      // if()
    }, []);
    const onImageChange =(e)=>{
      console.log("onImageChange",e.target.files[0])
      setCategoryImage(e.target.files[0])
      setCategoryImageView(URL.createObjectURL(e.target.files[0]))
    }
    async function updateCategory(){
        let sendImage=""
        console.log("no image")

        try{ 
         
          if(categoryImageName == ""){
            if(state.categoryImage !=null){
              sendImage=state.categoryImage
            }
          }

          dispatch(loadingActions.increment());
          const id =state.id
          const data= await dispatch(updateCategories( id,description,sendImage,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali))
          //console.log("data",data);
          if(data){
            toast.success(state.description+" has been updated")
            navigate(`/category-management`)
          }
          else{
            toast.error(data.message);
          }
        }
        catch(error){
            toast.error(error);
            //console.log(error);
        }finally{
          dispatch(loadingActions.decrement());
        }

    }
    async function updateCategoryImage(sendImage){
     
      console.log("image")

      try{ 
       
  
        dispatch(loadingActions.increment());
        const id =state.id
        console.log("id",id);
        const data= await dispatch(updateCategories( id,description,sendImage,msba,cidb,eaab,saqa,sacap,hnbrc,iopsa,pirb,medical,sali))
        console.log("data",data);
        if(data){
          toast.success(state.description+" has been updated")
          // navigate(`/category-management`)
        }
        else{
          toast.error(data.message);
        }
      }
      catch(error){
          toast.error(error);
          //console.log(error);
      }finally{
        dispatch(loadingActions.decrement());
      }

  }
    // if(isAuth){
    //   return<Navigate to="/"/>
    // }

    const onEmailChange=(event)=>{
        setIsInValid(false);
        setEmail(event.target.value);
    }
    const onPasswordChange=(event)=>{
        setIsInValid(false);
        setPassword(event.target.value);
    }
    const uploadImage = async(filename) => {
     
 
      const S3_BUCKET = "pwa-static";
      const REGION = "af-south-1";

      AWS.config.update({
        accessKeyId: "AKIA6L6CEKX3HAO3ISFP",
        secretAccessKey: "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",
      });
      const s3 = new AWS.S3({
        params: { Bucket: S3_BUCKET },
        region: REGION,
      });

      const params = {
        Bucket: S3_BUCKET,
        Key: filename,
        Body: categoryImage,
        // ContentType:mime.getType(filename)
      };
    

      var upload = s3
        .putObject(params)
        .on("httpUploadProgress", (evt) => {
          console.log(
            "Uploading " + parseInt((evt.loaded * 100) / evt.total) + "%"
          );
        })
        .promise();

      await upload.then((err, data) => {
        console.log(err);

        // alert("File uploaded successfully.");
      });

    };

    const onSubmit=async(event)=>{
        event.preventDefault();
        if(description==null||description==''){
          toast.error("Category name can not be empty");
     
        }
        if(categoryImage !=null){
          console.log("hi")
          const currentDate = new Date().valueOf()
          const filename =`public/${currentDate}.jpg`
          // const img = await fetchImageFromUri(categoryImage);
          // console.log(filename);
          // console.log('progile image', img)
          setCategoryImageName("https://pwa-static.s3.af-south-1.amazonaws.com/"+filename)
          await uploadImage(filename);
          const sendImage="https://pwa-static.s3.af-south-1.amazonaws.com/"+filename

          updateCategoryImage(sendImage)
        }
        else{
          // //console.log(sali);
          updateCategory()
        // getUser(email,password);
        }

    }
    return(
    <Box
      sx={{
        backgroundColor: 'background.default',
        minHeight: '100%',
        // p: '120',
        paddingTop:'180px'
      }}
    >
      <Container maxWidth="sm">
        <Card sx={{margin:'auto'}}>

        <CardContent
          sx={{
              backgroundColor:"primary.main",
            display: 'flex',
            flexDirection: 'column',
            p:'0'
          }}
        >
          
            <Box sx={{display:'flex', flexDirection:'inherit', alignItems:'center'}}
            >
              <Typography
                color="white"
                variant="h3"
              >
                Edit Category
              </Typography>
            </Box>
            </CardContent>

            <CardContent
                sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    p:'1'
                }}
        >
            <Box
              sx={{
                flexGrow: 1,
                mt: 2
              }}>
              <form onSubmit={(e)=>onSubmit(e)}>
              <FormLabel component="legend" sx={{marginTop:'5px'}}>Category Name</FormLabel>
                <TextField
                  fullWidth
                  // label="Category Name"
                  margin="normal"
                  name="description"
                  type="default"
                  variant="outlined"
                  value={description}
                  defaultValue={description}
                  onChange={(e)=>setDescription(e.target.value)}
                  // error={isInValid}
                  // helperText={isInValid? 'Invalid Email' : ' '}
                  sx={{marginBottom:'2px',marginTop:'2px'}}
                />
                 <FormLabel component="legend" sx={{marginTop:'5px'}}>Category Image</FormLabel>
                { categoryImageView ==null  ?
                  <Button
                    component="label"
                    role={undefined}
                    variant="contained"
                    tabIndex={-1}
                    startIcon={<CloudUploadIcon />}
                  >
                    Upload Image
                    <input
                      type="file"
                      accept='image/*'
                      hidden
                      onChange={onImageChange}
                    />
                  </Button>
                  :
                  <Box
                    sx={{
                      justifyContent:'center',
                      alignItems:'center',
                      display:'flex',
                      m:2
                    }}
                  >
                    <Avatar
                      src={categoryImageView}
                      sx={{
                        height: 200,
                        width: 200
                      }}
                    />
                    <Button
                      component="label"
                      role={undefined}
                      variant="contained"
                      tabIndex={-1}
                      startIcon={<CloudUploadIcon />}
                    >
                      Change Image
                      <input
                        type="file"
                        accept='image/*'
                        hidden
                        onChange={onImageChange}
                      />
                    </Button>
                  </Box>                
                }
                 <FormLabel component="legend" sx={{marginTop:'5px'}}>Accreditations</FormLabel>
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={msba}
                        onChange={(e)=>setmsba(!msba)}
                        
                    />
                    )}
                    label="msba"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={cidb}
                        onChange={(e)=>setcidb(!cidb)}
                        
                    />
                    )}
                    label="cidb"
                />
              <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={eaab}
                        onChange={(e)=>seteaab(!eaab)}
                        
                    />
                    )}
                    label="eaab"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={saqa}
                        onChange={(e)=>setsaqa(!saqa)}
                        
                    />
                    )}
                    label="saqa"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={sacap}
                        onChange={(e)=>setsacap(!sacap)}
                        
                    />
                    )}
                    label="sacap"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={hnbrc}
                        onChange={(e)=>sethnbrc(!hnbrc)}
                        
                    />
                    )}
                    label="hnbrc"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={iopsa}
                        onChange={(e)=>setiopsa(!iopsa)}
                        
                    />
                    )}
                    label="iopsa"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={pirb}
                        onChange={(e)=>setpirb(!pirb)}
                        
                    />
                    )}
                    label="pirb"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={medical}
                        onChange={(e)=>setmedical(!medical)}
                        
                    />
                    )}
                    label="medical"
                />
                <FormControlLabel
                    control={(
                    <Checkbox
                        color="primary"
                        checked={sali}
                        onChange={(e)=>setsali(!sali)}
                        
                    />
                    )}
                    label="sali"
                />
                <Button
                    color="error"
                    fullWidth
                    size="large"
                    // type="submit"
                    variant="contained"
                    onClick={()=> navigate(`/category-management`)}
                    
                >
                    Cancel
                  </Button>
                  <Button
                    color="primary"
                    fullWidth
                    size="large"
                    type="submit"
                    variant="contained"
                  >
                    Save
                  </Button>
              </form>
            </Box>
          </CardContent>
        </Card>
      </Container>
    </Box>
    );
};
  
  export default CategoryEdit;
  